import React from 'react';
import { graphql } from "gatsby";
import ReactMarkdown from 'react-markdown';
import Link from '../components/link';

import Layout from '../components/layout';
import ImageAndText from '../components/image-and-text/image-and-text';
import TitleAndText from '../components/title-and-text/title-and-text';
import References from '../components/references/references';
import Banner from '../components/banner/banner';
import Contact from '../components/contact/contact';

export default function AboutUsPage({ data }) {
  return (
    <Layout title="O nas">
      <ImageAndText img={data.strapiAbout.aboutUsImage.childImageSharp.fluid} bg={data.aboutBg.childImageSharp.fluid} id="about-us">
        <h1 className="border">{data.strapiAbout.aboutUsTopSectionTitle}</h1>
        <ReactMarkdown source={data.strapiAbout.aboutUsTopSectionContent} />
        <Link className="line-button" style={{fontWeight: '400', float: 'right'}} to="/oferta">Sprawdź naszą ofertę</Link>
      </ImageAndText>
      <Banner buttonText="Aplikuj" buttonLink="/kariera">
        Dołącz do naszego zespołu!
      </Banner>
      <TitleAndText title={data.strapiAbout.aboutUsBottomSectionTitle}>
        <ReactMarkdown source={data.strapiAbout.aboutUsBottomSectionContent} />
      </TitleAndText>
      <References references={data.strapiAbout.references}/>
      <Contact />
    </Layout>
  )
}

export const query = graphql`
  {
    strapiAbout {
      aboutUsBottomSectionContent
      aboutUsBottomSectionTitle
      aboutUsTopSectionContent
      aboutUsTopSectionTitle
      aboutUsImage {
        childImageSharp {
            fluid(maxWidth: 618) {
                ...GatsbyImageSharpFluid
            }
        }
      }
      references {
        id
        referenceContent
        referenceSignature
      }
    }
    aboutBg: file(relativePath: {eq: "about_bg.jpg"}) {
      id
      childImageSharp {
          fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
          }
      }
    }
  }
`