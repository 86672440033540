import React from 'react';
import Slider from 'react-slick';
import ReactMarkdown from 'react-markdown';
import BackgroundImage from 'gatsby-background-image';

import './references.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Container from '../container/container';
import { graphql, useStaticQuery } from 'gatsby';

const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: false,
}

const References = ({ references }) => {
    const data = useStaticQuery(graphql`
        {
            referencesBg: file(relativePath: {eq: "references_bg.jpg"}) {
                id
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <BackgroundImage Tag="section" id="references" className="references" fluid={data.referencesBg.childImageSharp.fluid}>
            <Container>
                <h1>Referencje</h1>
                <div className="references-wrapper">
                    <Slider {...settings} >
                        {references.map(reference => (
                            <div className="reference" key={reference.id}>
                                <div className="reference-quote">
                                    <ReactMarkdown source={reference.referenceContent}/>
                                </div>
                                <div className="reference-sign">
                                    <ReactMarkdown source={reference.referenceSignature}/>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </Container>
        </BackgroundImage>
    )
}

export default References;