import React from 'react';
import Container from '../container/container';

import './title-and-text.scss';

const TitleAndText = ({title, children}) => {
    return (
        <section id="fact-about-us" className="title-and-text">
            <Container>
                <div className="title-and-text-wrapper">
                    <div className="title">
                        <h2 className="border light">
                            {title}
                        </h2>
                    </div>
                    <div className="text-content">
                        {children}
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default TitleAndText;